<template>
  <v-app-bar
  >
    <v-app-bar-nav-icon>
      <img src="@/assets/logo.svg" alt="" srcset="">
    </v-app-bar-nav-icon>
    <v-toolbar-title>ITMR Developments - Intranet</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn
        icon
        v-for="(item, i) in routes"
        :key="i"
        @click="$router.push(item.route)"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-text="item.icon"
              v-bind="attrs"
              v-on="on"
            ></v-icon>
          </template>
          <span>{{item.text}}</span>
        </v-tooltip>
      </v-btn>

      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>fas fa-user</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-subheader>ANZEIGE</v-subheader>
          <v-list-item @click="$store.state.privacy = !$store.state.privacy">
            <v-list-item-icon>
              <v-icon v-text="$store.state.privacy ? 'fa-eye-slash' : 'fa-eye'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Privatsphäre-Modus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-subheader>ACCOUNT</v-subheader>
          <v-list-item @click="$router.push('/account')">
            <v-list-item-content>
              <v-list-item-title>{{$store.state.authInfo.preName}} {{$store.state.authInfo.lastName}}</v-list-item-title>
              <v-list-item-subtitle>{{$store.state.authInfo.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-content>
              <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <v-menu
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          class="hidden-md-and-up"
        >
          <v-icon>fas fa-bars</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader>SEITEN</v-subheader>
        <v-list-item 
          v-for="(item, i) in routes"
          :key="i"
          @click="$router.push(item.route)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>ANZEIGE</v-subheader>
        <v-list-item @click="$store.state.privacy = !$store.state.privacy">
          <v-list-item-icon>
            <v-icon v-text="$store.state.privacy ? 'fa-eye-slash' : 'fa-eye'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Privatsphäre-Modus</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-subheader>ACCOUNT</v-subheader>
        <v-list-item @click="$router.push('/account')">
          <v-list-item-content>
            <v-list-item-title>{{$store.state.authInfo.preName}} {{$store.state.authInfo.lastName}}</v-list-item-title>
            <v-list-item-subtitle>{{$store.state.authInfo.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
  },
  data() {
    return {
      routes: [
        {
          text: "Start",
          route: "/",
          icon: "fas fa-home",
        },
        {
          text: "Statistiken",
          route: "/stats",
          icon: "fas fa-chart-pie",
        },
        {
          text: "Kunden",
          route: "/clients",
          icon: "fas fa-users",
        },
        {
          text: "Projekte",
          route: "/projects",
          icon: "fas fa-tasks",
        },
        {
          text: "Rechnungen",
          route: "/invoices",
          icon: "fas fa-file-invoice-dollar",
        },
        {
          text: "Kurzlinks",
          route: "/shorts",
          icon: "fas fa-paperclip",
        },
        {
          text: "Ausgaben",
          route: "/expenses",
          icon: "fas fa-receipt",
        },
      ],
    };
  },
}
</script>

<style>

</style>